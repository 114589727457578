import React, { useContext, useEffect } from 'react'
import { GetStaticProps } from 'next'
import Head from 'next/head'

import PageDataService from '@lib/pageData/pageDataService'
import { IMenuItem } from '@modules/menu/types'
import Layout from '@components/Layout/Layout'
import ShopContext from '@context/ShopContext'
import Stage from '@components/content/homepage/Stage'
import { IHomepage } from '@modules/homepage/types'
import { IProductSnippetsBySlug } from '@modules/products/types'
import ContentSections from '@modules/contentSections/ContentSections'

interface HomepageProps {
  homepage: IHomepage
  productSnippetsBySlug: IProductSnippetsBySlug
  topMenu: IMenuItem[]
}

const HomePage = ({ homepage, productSnippetsBySlug, topMenu }: HomepageProps) => {
  const { setProductsBySlug } = useContext(ShopContext)

  useEffect(() => {
    setProductsBySlug(productSnippetsBySlug)
  }, [productSnippetsBySlug])

  return (
    <Layout
      description={homepage.seoTags.description}
      metaImage={homepage.seoTags.image.url}
      title={homepage.seoTags.title}
      topMenu={topMenu}>
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="yoself - Home of Balance"
          href="https://yoself.podigee.io/feed/mp3"
        />
      </Head>
      <Stage stageSection={homepage.stageSection} />
      <ContentSections
        contentSections={homepage.contentSections}
        productSnippetsBySlug={productSnippetsBySlug}
      />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const pageDataService = new PageDataService(context)

  const { homepage, topMenu, productSnippetsBySlug } = await pageDataService.getHomePageData()
  return {
    props: {
      topMenu,
      homepage,
      productSnippetsBySlug,
      key: 'homepage'
    },
    ...pageDataService.getSettings()
  }
}

export default HomePage
