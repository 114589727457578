import React from 'react'
import Image from 'next/image'
import LinkOrAnchorLink from '@components/LinkOrAnchorLink'
import { IHomepageStageSection } from '@modules/homepage/types'

type StageSectionProps = {
  stageSection: IHomepageStageSection
}

const Stage = ({ stageSection }: StageSectionProps) => {
  const leftImage = stageSection.images[0]
  const rightImage = stageSection.images[1]
  return (
    <section className="w-full bg-gray-200">
      <div className="flex w-full mx-auto overflow-hidden">
        <div className="flex relative w-full lg:w-7/12 xl:w-8/12 h-75vh md:h-85vh lg:h-70vh xl:h-70vh 2xl:h-80vh overflow-hidden items-center justify-center">
          <Image
            alt={leftImage.alt || 'Schlaf'}
            className="object-cover h-inherit"
            src={leftImage.responsiveImage.src}
            fill
            priority
            quality={80}
            sizes="(min-width: 768px) 60vw, 100vw"
            style={{ objectPosition: leftImage.focalPoint }}
          />

          <div className="items-end absolute z-10 left-0 bottom-0 pr-16 pt-32 lg:pr-4 pb-10 lg:pb-8 xl:pb-16 2xl:pb-24 pl-4 lg:pl-8 xl:pl-16 2xl:pl-24 w-full bg-gradient-to-t from-gray-900-45">
            <h1 className="headline-stage max-w-lg text-white w-full mb-8">
              {stageSection.headline}
            </h1>

            <LinkOrAnchorLink callToAction={{ ...stageSection.callToAction }} whiteBlackHover />
          </div>
        </div>

        <div className="relative lg:flex hidden w-5/12 xl:w-4/12 -mt-8 h-75vh md:h-85vh lg:h-70vh xl:h-70vh 2xl:h-80vh fullhd:h-70vh overflow-hidden items-center justify-center">
          <Image
            alt={rightImage.alt}
            className="object-cover h-inherit"
            src={rightImage.responsiveImage.src}
            fill
            priority
            sizes="(min-width: 768px) 25vw, 0vw"
            style={{ objectPosition: rightImage.focalPoint }}
          />
        </div>
      </div>
    </section>
  )
}

export default Stage
